import Router from "next/router";

export default function redirectTo(destination, { res, status } = {}) {
  if (typeof window === "undefined") {
    if (res) {
      res.writeHead(302, { Location: "/signup" });
      res.end();
    }
  } else {
    if (destination[0] === "/" && destination[1] !== "/") {
      Router.push(destination);
    } else {
      window.location = destination;
    }
  }
}
