import { useEffect, useState, useMemo } from "react";
import dynamic from "next/dynamic";
const App = dynamic(() => import("../components/App"));
import Head from "next/head";
import axios from "axios";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { PreloadThumborImage } from "../utils/thumborjs";
import { getItemWithExpiry } from "../utils/localstorage";
import { useInView } from "react-intersection-observer";

import { Api } from "../networks/Api";
import { useRouter } from "next/router";

const imageUrl = process.env.NEXT_PUBLIC_IMAGE_URL;

const Empty = () => {
  return (
    <div className="component-product-swipper relative ">
      <div className="flex justify-between h-[38px]">
        <div class="bg-gray-200 animate-pulse h-[32px] w-[79px] rounded" />
        <div class="bg-gray-200 animate-pulse h-[32px] w-[79px] rounded" />
      </div>

      <div className="swiper-container overflow-hidden flex space-x-[10px]">
        {[1, 2, 3, 4, 5]?.map((v) => {
          return (
            <div key={`empty-${v}`} className="card-product">
              <div className="card-product-img">
                <div class="bg-gray-200 animate-pulse h-64 w-64"></div>
              </div>
              <div className="card-product-detail">
                <div class="space-y-1 title3line">
                  <div class="bg-gray-200 animate-pulse h-3 w-4/4 rounded"></div>
                  <div class="bg-gray-200 animate-pulse h-3 w-4/4 rounded"></div>
                  <div class="bg-gray-200 animate-pulse h-3 w-3/4 rounded"></div>
                </div>

                <div class="space-y-1 price-with-discount">
                  <div class="bg-gray-200 animate-pulse h-3 w-2/4 rounded"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Component
const BannerSSR = dynamic(() => import("../components/Home/Banner"), {
  ssr: true,
});
const Banner = dynamic(() => import("../components/Home/Banner"), {
  ssr: false,
  loading: () => <div />,
});
const ProductSlide = dynamic(() => import("../components/ProductSlideScroll"), {
  ssr: false,
  loading: () => <Empty />,
});
const SocialSlideScroll = dynamic(
  () => import("../components/Home/SocialSlideScroll"),
  { ssr: false, loading: () => <div /> }
);
const Brand = dynamic(() => import("../components/Home/Brand"), {
  ssr: false,
  loading: () => <div />,
});
const Firstcontent = dynamic(() => import("../components/Home/Firstcontent"), {
  ssr: false,
  loading: () => <div />,
});
const Secondcontent = dynamic(
  () => import("../components/Home/Secondcontent"),
  {
    ssr: false,
    loading: () => <div />,
  }
);
const Thirdcontent = dynamic(() => import("../components/Home/Thirdcontent"), {
  ssr: false,
  loading: () => <div />,
});

const CategoryGender = dynamic(
  () => import("../components/Home/CategoryGender"),
  {
    ssr: false,
    loading: () => <div />,
  }
);

const page = 1;
const size = 10;

export async function getServerSideProps(ctx) {
  let brandList = [];
  let bannerTop = [];
  let recommendedProducts = [];
  let newProducts = [];
  let bestProducts = [];
  let categoryGenderList = [];

  const userAgent = ctx.req.headers["user-agent"] || "";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);

  try {
    const [
      brandListResponse,
      bannerTopResponse,
      recommendedProductsResponse,
      newProductsResponse,
      bestProductsResponse,
      categoryGenderResponse,
    ] = await Promise.all([
      axios.get(`${Api.getFeedBrandList}`, Api.Header()),
      axios.get(`${Api.getFeedBannerList}/top`, Api.Header()),
      axios.get(
        `${Api.getFeedProductList}?page=${page}&size=${size}&type=recommend`,
        Api.Header()
      ),
      axios.get(
        `${Api.getFeedProductList}?page=${page}&size=${size}&type=new`,
        Api.Header()
      ),
      axios.get(
        `${Api.getFeedProductList}?page=${page}&size=${size}&type=best`,
        Api.Header()
      ),
      axios.get(`${Api.getCategeryGender}`, Api.Header()),
    ]);

    if (brandListResponse?.status == 200) {
      // แปลงโครงสร้างข้อมูล
      brandList = brandListResponse?.data?.data?.reduce((acc, item) => {
        acc[item.number] = item.name;
        return acc;
      }, {});
    }

    if (bannerTopResponse?.status == 200) {
      bannerTop = bannerTopResponse?.data?.data;
    }
    if (recommendedProductsResponse?.status === 200) {
      recommendedProducts = recommendedProductsResponse?.data?.data;
    }
    if (newProductsResponse?.status === 200) {
      newProducts = newProductsResponse?.data?.data;
    }
    if (bestProductsResponse?.status === 200) {
      bestProducts = bestProductsResponse?.data?.data;
    }
    if (categoryGenderResponse?.status === 200) {
      categoryGenderList = categoryGenderResponse?.data?.data;
    }
  } catch (e) {
    console.log(e);
  }

  return {
    props: {
      ...(await serverSideTranslations(ctx?.locale, ["common"])),
      brandList,
      bannerTop,
      recommendedProducts,
      newProducts,
      bestProducts,
      categoryGenderList,
      isMobile,
    },
    // revalidate: 60,
  };
}

const Home = ({
  categoryMenu,
  dataSeo,
  dataReferrer,
  brandList,
  bannerTop,
  recommendedProducts,
  newProducts,
  bestProducts,
  categoryGenderList,
  isMobile,
}) => {
  const router = useRouter();
  const queryParam = useMemo(() => {
    let param = router?.query?.aid ? `aid=${router?.query?.aid}` : ``;
    param = router?.query?.pid ? `${param}&pid=${router?.query?.pid}` : param;
    param = router?.query?.pf ? `${param}pf=${router?.query?.pf}` : param;

    if (router?.query?.utm_source) {
      param = param
        ? `${param}&utm_source=${router?.query?.utm_source}`
        : `?utm_source=${router?.query?.utm_source}`;
    }
    return param;
  }, [router?.query]);

  const { ref: refTop, inView: inViewTop } = useInView({
    threshold: 0,
  });
  const { ref: refBottom, inView: inViewBottom } = useInView({
    threshold: 0,
  });

  // const isMobile = useMediaQuery({ maxWidth: 575 });
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = getItemWithExpiry("token");
    setToken(storedToken);
  }, []);

  const image_path =
    bannerTop?.length >= 1
      ? bannerTop[0]?.website_image_path?.replace(/\/uploads/g, "")
      : "";

  const image_path_m =
    bannerTop?.length >= 1
      ? bannerTop[0]?.mobile_image_path?.replace(/\/uploads/g, "")
      : "";

  const mobileFirstImage = `${imageUrl}${image_path_m}`;
  const desktopFirstImage = `${imageUrl}${image_path}`;

  return (
    <App
      token={token}
      categoryMenu={categoryMenu}
      dataSeo={dataSeo}
      dataReferrer={dataReferrer}
    >
      <Head>
        {process.env.NEXT_PUBLIC_APP_ENV == "production" ? (
          <meta
            name="google-site-verification"
            content="dloZRLwFVKf-XdY_u3X1JJ4CgYf6tReRWP3Q9wmt3qk"
          />
        ) : (
          <></>
        )}
        {/* <link
          rel="preload"
          as="image"
          href={isMobile ? mobileFirstImage : desktopFirstImage}
        /> */}
        {isMobile ? (
          <PreloadThumborImage
            imagePath={mobileFirstImage}
            width={480}
            ratio={480 / 359}
          />
        ) : (
          <PreloadThumborImage
            imagePath={desktopFirstImage}
            width={1024}
            ratio={1024 / 324}
          />
        )}
      </Head>

      <div className="container mx-auto">
        {isMobile ? (
          <div className="block sm:hidden relative mySwiper-banner">
            <BannerSSR
              bannerData={bannerTop}
              indexValue={`mobile_image_path`}
              bannerWidth={480}
              bannerHeight={359}
              queryParam={queryParam}
              firstload={true}
              isMobile={isMobile}
            />
          </div>
        ) : (
          <div className="hidden sm:block relative mySwiper-banner">
            <BannerSSR
              bannerData={bannerTop}
              indexValue={`website_image_path`}
              bannerWidth={1024}
              bannerHeight={324}
              queryParam={queryParam}
              firstload={true}
              isMobile={isMobile}
            />
          </div>
        )}

        <ProductSlide
          title={`Best Seller`}
          url={`best${queryParam ? `?${queryParam}` : ``}`}
          products={bestProducts}
          brandData={brandList}
          queryParam={queryParam}
        />

        <div ref={refTop}>
          {inViewTop ? (
            categoryGenderList?.length > 0 && (
              <>
                <div className="hidden sm:block sm:px-4 lg:px-0">
                  <CategoryGender
                    content={categoryGenderList}
                    queryParam={queryParam}
                    isMobile={false}
                    imageWidth={560}
                    imageHeight={560}
                  />
                </div>
                <div className="block sm:hidden px-4">
                  <CategoryGender
                    content={categoryGenderList}
                    queryParam={queryParam}
                    isMobile={true}
                    imageWidth={560}
                    imageHeight={211}
                  />
                </div>
                <ProductSlide
                  title={`New Arrivals`}
                  url={`new${queryParam ? `?${queryParam}` : ``}`}
                  fetchUrl={`${Api.getFeedProductList}?page=${page}&size=${size}&type=new`}
                  brandData={brandList}
                  queryParam={queryParam}
                />
                <ProductSlide
                  title={`Recommended Products`}
                  url={`recommend${queryParam ? `?${queryParam}` : ``}`}
                  fetchUrl={`${Api.getFeedProductList}?page=${page}&size=${size}&type=recommend`}
                  brandData={brandList}
                  queryParam={queryParam}
                />
                <div className="hidden sm:block relative">
                  <div className="mySwiper-banner">
                    <Banner
                      url={`${Api.getFeedBannerList}/middle`}
                      indexValue={`website_image_path`}
                      bannerWidth={1024}
                      bannerHeight={324}
                      queryParam={queryParam}
                      isMobile={isMobile}
                    />
                  </div>
                </div>
                <div className="block sm:hidden relative">
                  <div className="mySwiper-banner">
                    <Banner
                      url={`${Api.getFeedBannerList}/middle`}
                      indexValue={`mobile_image_path`}
                      bannerWidth={480}
                      bannerHeight={359}
                      queryParam={queryParam}
                      isMobile={isMobile}
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            <>loading</>
          )}
        </div>
      </div>

      <div ref={refBottom}>
        {inViewBottom ? (
          <>
            <div className="container mx-auto my-5 px-4 lg:px-0">
              <Firstcontent queryParam={queryParam} />
            </div>
            <div className="border-t border-gray-200 my-5" />

            <div className="container mx-auto my-5 px-4 lg:px-0">
              <Secondcontent queryParam={queryParam} />
            </div>
            <div className="border-t border-gray-200 my-5" />

            <div className="container mx-auto my-5 px-4 lg:px-0">
              <Thirdcontent queryParam={queryParam} />
            </div>
            <div className="border-t border-gray-200 my-5" />

            <div className="container mx-auto py-4">
              <Brand queryParam={queryParam} />
            </div>

            <div className="bg-gray-100">
              <div className="container mx-auto py-4">
                <SocialSlideScroll />
              </div>
            </div>
          </>
        ) : (
          <>loading...</>
        )}
      </div>
    </App>
  );
};

export default Home;
