function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const setItemWithExpiry = (key, value, ttl = 600000) => {
  // ttl = 600000 คือ 10 นาที
  const now = new Date();

  // การตั้งค่า object ที่มีค่าที่ต้องการเก็บและ expiry time
  const item = {
    value: value,
    expiry: now.getTime() + ttl, // ttl = อายุข้อมูลที่ต้องการเก็บในมิลลิวินาที
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // หากไม่มีข้อมูล return null
  if (!itemStr) {
    return null;
  }

  if (!isJSON(itemStr)) {
    localStorage.removeItem(key);
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // หากข้อมูลหมดอายุแล้วลบข้อมูลออกจาก localStorage และ return null
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};
