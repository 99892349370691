import Head from "next/head";
import { useRouter } from "next/router";

const getSeoTitle = (locale, data_seo) => {
  if (locale === "th") {
    return data_seo?.seo_title || "";
  } else {
    return data_seo?.seo_title_en || data_seo?.seo_title || "";
  }
};
const getSeoDescription = (locale, data_seo) => {
  if (locale === "th") {
    return data_seo?.seo_description || "";
  } else {
    return data_seo?.seo_description_en || data_seo?.seo_description || "";
  }
};

const App = ({ data_seo, brand_filtered }) => {
  const router = useRouter();
  const { locale } = router;
  const pageUrl = router?.asPath
    ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}${router.asPath}`
    : "";

  const seoTitle = getSeoTitle(locale, data_seo);
  const seoDescription = getSeoDescription(locale, data_seo);

  return (
    <>
      {data_seo ? (
        <Head>
          <title>{seoTitle}</title>
          <meta name="author" content="Pinvestment" />
          <meta name="title" content={seoTitle} />
          <meta name="description" content={seoDescription} />
          <meta
            name="keywords"
            content="luxury29.io ,Luxury29, Luxury 29, สินค้าแบรนหรู, shopping, shop, Bags, Jewelries, Clothing, Watches, Footwear, Accessories"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={seoDescription} />

          {!router?.query?.id && (
            <meta
              name="twitter:image"
              content={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/luxury29-43.jpg`}
            />
          )}
          <meta property="fb:app_id" content="1686331181830759" />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content={seoTitle} />
          <meta property="og:title" content={seoTitle} />
          {!router?.query?.id && (
            <meta
              property="og:image"
              content={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/luxury29-43.jpg`}
            />
          )}
          <meta property="og:description" content={seoDescription} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:url" content={pageUrl} />

          <link
            rel="icon"
            href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/favicon.ico`}
            className="js-favicon"
          />
          {brand_filtered?.length == 0 && (
            <link rel="canonical" href={`${pageUrl.split("?")[0]}`} />
          )}
        </Head>
      ) : (
        <Head>
          <meta
            name="keywords"
            content="luxury29.io ,Luxury29, Luxury 29, สินค้าแบรนหรู, shopping, shop, Bags, Jewelries, Clothing, Watches, Footwear, Accessories"
          />

          {!router?.query?.id && (
            <meta
              name="twitter:image"
              content={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/luxury29-43.jpg`}
            />
          )}

          {!router?.query?.id && (
            <meta
              property="og:image"
              content={`${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/luxury29-43.jpg`}
            />
          )}
          {brand_filtered?.length == 0 && (
            <link rel="canonical" href={`${pageUrl.split("?")[0]}`} />
          )}
        </Head>
      )}
    </>
  );
};

export default App;
