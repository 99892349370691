import dynamic from "next/dynamic";
import { useEffect, useState, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
const Button = dynamic(() => import("antd/es/button"), { ssr: false });
import Input from "antd/lib/input";
import Layout from "antd/lib/layout";
import NextImage from "next/image";
import { CloseOutlined } from "@ant-design/icons";
const { Content } = Layout;
import axios from "axios";
import { Api } from "../../networks/Api";
import { useTranslation } from "next-i18next";
import Script from "next/script";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useFetchBrandList from "../../hook/useFetchBrandList";
import useStore from "../../utils/data-store";

const Seo = dynamic(() => import("./SEO"), { ssr: true });
const Head = dynamic(() => import("./Header"), { ssr: false });
const Pdpa = dynamic(() => import("./pdpa"), { ssr: false });
const Footer = dynamic(() => import("./Footer"), { ssr: false });

const app_env = process.env.NEXT_PUBLIC_APP_ENV;

dayjs.extend(utc);
dayjs.extend(timezone);

const { Search } = Input;

const App = (props) => {
  const router = useRouter();
  const setFingerprint = useStore((state) => state.setFingerprint);

  const queryParam = useMemo(() => {
    let param = router?.query?.aid ? `aid=${router?.query?.aid}` : ``;
    param = router?.query?.pid ? `${param}&pid=${router?.query?.pid}` : param;
    param = router?.query?.pf ? `${param}pf=${router?.query?.pf}` : param;

    if (router?.query?.utm_source) {
      param = param
        ? `${param}&utm_source=${router?.query?.utm_source}`
        : `?utm_source=${router?.query?.utm_source}`;
    }
    return param;
  }, [router?.query]);

  const data_seo = props?.dataSeo;
  const dataReferrer = props?.dataReferrer || "";

  const postStatData = async (url, postData, token) => {
    try {
      await axios.post(url, postData, Api.Header(token || ""));
    } catch (error) {
      console.error(`Error posting stat data to ${url}:`, error);
    }
  };
  const postAccessLog = async (postData) => {
    try {
      const url = `${Api.createAccessLog}`;
      await axios.post(url, postData, Api.Header());
    } catch (error) {
      console.error("Error posting access log:", error);
    }
  };

  const { t } = useTranslation("common");

  const brandData = useFetchBrandList(props?.token);
  const [openSearch, setOpenSearch] = useState(false);

  const brand_filtered = brandData.filter((brand) =>
    brand?.name?.toLowerCase().includes(router?.query?.category?.toLowerCase())
  );

  useEffect(() => {
    const fetchFingerprintData = () => {
      try {
        const storedFingerprint = Cookies.get("uuid") || uuidv4();
        Cookies.set("uuid", storedFingerprint, { expires: 7, path: "/" });
        setFingerprint(storedFingerprint);
      } catch (e) {
        console.error("Error in fetchFingerprintData:", e);
      }
    };

    fetchFingerprintData();
  }, []);

  useEffect(() => {
    const postStatDataHandler = () => {
      if (router?.query) {
        const { aid, pid, pf } = router.query;
        const { asPath } = router;
        const baseUrl = asPath.split("?")[0];

        if (aid && pid) {
          const url = props?.token
            ? `${Api.ambassadorStatMember}`
            : `${Api.ambassadorStat}`;
          postStatData(
            url,
            {
              action: "view",
              url: baseUrl,
              aid,
              pid,
              uuid: Cookies.get("uuid"),
            },
            props?.token
          );
        }

        if (pf) {
          const url = props?.token
            ? `${Api.webpromotionStatMember}`
            : `${Api.webpromotionStat}`;
          postStatData(
            url,
            {
              action: "view",
              url: baseUrl,
              pid: pf,
              uuid: Cookies.get("uuid"),
            },
            props?.token
          );
        }
      }
    };

    postStatDataHandler();
  }, [router.query, props?.token]);

  useEffect(() => {
    const postAccessLogHandler = () => {
      try {
        const postData = {
          url: window?.location?.href,
          referer: dataReferrer,
          user_agent: navigator?.userAgent,
          uuid: Cookies.get("uuid"),
        };
        postAccessLog(postData);
      } catch (e) {
        console.error("Error in postAccessLogHandler:", e);
      }
    };

    postAccessLogHandler();
  }, [dataReferrer]);

  const onSearch = (value) => {
    router.push(`/all?search=${value}${queryParam ? `&${queryParam}` : ``}`);
  };

  return (
    <Layout>
      <Seo data_seo={data_seo} brand_filtered={brand_filtered} />
      <Head
        props={props}
        setOpenSearch={setOpenSearch}
        queryParam={queryParam}
      />
      <Content>
        <Layout className="layout-main-content">
          <Content
            className={!props?.noHeader ? `main-content` : `noheader-content`}
          >
            {openSearch && (
              <div className="container-search">
                <Search
                  placeholder="Search for brand, color, etc."
                  onSearch={onSearch}
                />
                <Button
                  className="btn-close"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                >
                  <CloseOutlined />
                </Button>
              </div>
            )}
            {props.children}
          </Content>
          {!props?.noHeader && (
            <>
              <Footer t={t} />
              <Pdpa />
            </>
          )}
        </Layout>

        {!props?.disableLine && (
          <Link href={`https://lin.ee/hfscV5M`}>
            <div target="_blank" rel="noopener noreferrer">
              <div className="line">
                <NextImage
                  src={"/static/images/icon-line.webp"}
                  className="line"
                  alt="line"
                  width={60}
                  height={60}
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  unoptimized
                />
              </div>
            </div>
          </Link>
        )}
      </Content>

      {app_env == "production" ? (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-WG86W24EG2"
            strategy="afterInteractive"
            async
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());

         gtag('config', 'G-WG86W24EG2');
        `}
          </Script>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default App;
