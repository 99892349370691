export const BaseUrl = `${process.env.NEXT_PUBLIC_API_URL}/api/v1`;
export const PublicUrl = `${process.env.NEXT_PUBLIC_API_URL}/public`;
export const DevUrl = `${process.env.NEXT_PUBLIC_API_DEV_URL}/api/v1`;

export const Api = {
  Header(token = null) {
    if (token == null) {
      return {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
    } else {
      return {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      };
    }
  },
  Signup: `${BaseUrl}/auth/signup`,
  VerifyOTP: `${BaseUrl}/auth/verify/code`,
  ResetPassword: `${BaseUrl}/auth/resetpassword`,
  Signin: `${BaseUrl}/auth/signin`,
  SocialSignin: `${BaseUrl}/auth/social-signin`,
  CheckEmail: `${BaseUrl}/auth/check-email`,
  CheckAccount: `${BaseUrl}/auth/check-account`,
  VerifyEmail: `${BaseUrl}/auth/verify-email`,
  AccountDelete: `${BaseUrl}/auth/delete`,
  UserProfile: `${BaseUrl}/member/profile`,
  getUserDetail: `${BaseUrl}/member`,
  createUserDetail: `${BaseUrl}/member`,
  updateUserDetail: `${BaseUrl}/member`,
  deleteUserDetail: `${BaseUrl}/member`,
  getFeedProductList: `${BaseUrl}/feed/product/all`,
  getFeedProductListV2: `${BaseUrl}/feed/product/all-v2`,
  getFeedProductNew: `${BaseUrl}/feed/product/new`,
  getFeedProductTest: `${BaseUrl}/feed/product/test`,
  getFeedProductCategory: `${BaseUrl}/feed/product/category`,
  getProductDetail: `${BaseUrl}/product`,
  getFeedBrandList: `${BaseUrl}/feed/brand/all`,
  getFeedBrandHome: `${BaseUrl}/feed/brand/home`,
  getFeedCategoryList: `${BaseUrl}/feed/category/all`,
  checkHasSaleProduct: `${BaseUrl}/feed/has-sale-product`,
  checkHasLiveProduct: `${BaseUrl}/feed/has-live-product`,
  getFeedGroupCategory: `${BaseUrl}/feed/category/group`,
  getFeedSubCategory: `${BaseUrl}/feed/category/sub`,
  getCartList: `${BaseUrl}/cart/all`,
  addCart: `${BaseUrl}/cart`,
  deleteCart: `${BaseUrl}/cart`,
  getAddressList: `${BaseUrl}/address`,
  getAddressDetail: `${BaseUrl}/address`,
  addAddress: `${BaseUrl}/address`,
  updateAddress: `${BaseUrl}/address`,
  deleteAddress: `${BaseUrl}/address`,
  getCreditcardList: `${BaseUrl}/creditcard`,
  getCreditcardDetail: `${BaseUrl}/creditcard`,
  addCreditcard: `${BaseUrl}/creditcard`,
  updateCreditcard: `${BaseUrl}/creditcard`,
  deleteCreditcard: `${BaseUrl}/creditcard`,
  getColorList: `${BaseUrl}/color`,
  getFeedBannerList: `${BaseUrl}/feed/banner`,
  addContact: `${BaseUrl}/contact`,

  getOrderSummary: `${BaseUrl}/order/summary`,
  addOrder: `${BaseUrl}/order`,
  getOrderList: `${BaseUrl}/order`,
  getOrderDetail: `${BaseUrl}/order`,
  voidOrder: `${BaseUrl}/order/void`,
  updateOrderStatus: `${BaseUrl}/order/checkout/status`,
  createPersonalshopping: `${BaseUrl}/personalshopping`,

  ambassadorStat: `${BaseUrl}/ambassador/stat`,
  ambassadorStatMember: `${BaseUrl}/ambassador/stat-member`,

  webpromotionStat: `${BaseUrl}/webpromotion/stat`,
  webpromotionStatMember: `${BaseUrl}/webpromotion/stat-member`,

  checkVoucher: `${BaseUrl}/voucher/code`,
  checkVoucherAuth: `${BaseUrl}/voucher/auth/code`,
  listMyVoucher: `${BaseUrl}/voucher/list/myvoucher`,

  getGroupCategory: `${BaseUrl}/groupcategory`,

  webpromotionAll: `${BaseUrl}/webpromotion/discount-all`,

  contentHomepage: `${BaseUrl}/contenthomepage`,
  contentHomepageAll: `${BaseUrl}/contenthomepage/all`,

  feedfirstcontent: `${PublicUrl}/feed/firstcontent.json`,
  feedsecondcontent: `${PublicUrl}/feed/secondcontent.json`,
  feedthirdcontent: `${PublicUrl}/feed/thirdcontent.json`,
  feedsocialcontent: `${PublicUrl}/feed/socialcontent.json`,
  feedbrandhome: `${PublicUrl}/feed/brandhome.json`,
  feedbannermiddle: `${PublicUrl}/feed/bannermiddle.json`,

  createAccessLog: `${BaseUrl}/log/access/create`,

  getPageSEOUrl: `${DevUrl}/pageseo/page`,
  getPageSEOBrand: `${DevUrl}/pageseo/brand`,
  getPageSEOProduct: `${DevUrl}/pageseo/product`,
  getPageSEOBlog: `${DevUrl}/pageseo/blog`,
  getFeedArticleList: `${DevUrl}/feed/article/all`,
  getFeedArticleDetail: `${DevUrl}/feed/article`,
  getFeedArticleByBrand: `${DevUrl}/feed/article-by-brand`,
  getFeedArticleByBrandWithoutHtml: `${DevUrl}/feed/article-by-brand-without-html`,
  getFeedBlog: `${DevUrl}/feed/blog/all`,

  getBranch: `${BaseUrl}/branch`,
  getCountry: `${BaseUrl}/country`,

  getCurrencyexchangeList: `${BaseUrl}/currencyexchange/list`,

  paypalCreateOrders: `${BaseUrl}/paypal/orders`,
  paypalOrderCapture: (tempOrderId, orderId) =>
    `${BaseUrl}/paypal/orders/${tempOrderId}/${orderId}/capture`,

  getPaymentMethod: `${BaseUrl}/paymentmethod`,

  getCategeryGender: `${BaseUrl}/categorygender`,
};
