import ThumborJS from "thumborjs";
const thumborKey = process.env.NEXT_PUBLIC_THUMBOR_KEY;
const thumborUrl = process.env.NEXT_PUBLIC_THUMBOR_URL;

const generateThumborUrl = (imagePath, width, ratio) => {
  // const ratio = bannerWidth / bannerHeight;
  const height = Math.ceil(width / ratio);
  return new ThumborJS(thumborUrl, thumborKey)
    .imgpath(imagePath)
    .resize(width, height)
    .genurl();
};

const generateSrcSet = (imagePath, width, ratio) => {
  const sizes = [320, 480, 640, 750, 1080, 1280, 1920, 2560];
  return sizes
    .map((size) => {
      const result_size = size > width ? width : size;
      const thumborUrl = generateThumborUrl(imagePath, result_size, ratio);
      return `${thumborUrl} ${result_size}w`;
    })
    .join(", ");
};

// การใช้งานใน React
export const PreloadThumborImage = ({ imagePath, width, ratio }) => {
  // const srcSet = generateSrcSet(imagePath, width, ratio);
  return (
    <link
      rel="preload"
      as="image"
      href={generateThumborUrl(imagePath, width, ratio)}
      // imageSrcSet={srcSet}
    />
  );
};
