import { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../networks/Api";

const useFetchBrandList = (token) => {
  const [brandData, setBrandData] = useState([]);

  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const response = await axios.get(
          Api.getFeedBrandList,
          Api.Header(token)
        );
        if (response.status === 200) setBrandData(response.data.data);
      } catch (e) {
        console.error("Error fetching brand data:", e);
      }
    };

    fetchBrandData();
  }, [token]);

  return brandData;
};

export default useFetchBrandList;
