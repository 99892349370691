import { create } from "zustand";

const useBearStore = create((set) => ({
  cartData: [],
  setCartData: (cartData) => set({ cartData }),
  currency: "thb",
  setCurrency: (currency) => set({ currency }),
  currencyAll: {
    chf: { value: "chf", label: "🇨🇭 CHF", symbol: "CHF" },
    cny: { value: "cny", label: "🇨🇳 CNY", symbol: "¥" },
    dkk: { value: "dkk", label: "🇩🇰 DKK", symbol: "kr" },
    eur: { value: "eur", label: "🇪🇺 EUR", symbol: "€" },
    gbp: { value: "gbp", label: "🇬🇧 GBP", symbol: "£" },
    hkd: { value: "hkd", label: "🇭🇰 HKD", symbol: "$" },
    jpy: { value: "jpy", label: "🇯🇵 JPY", symbol: "¥" },
    sgd: { value: "sgd", label: "🇸🇬 SGD", symbol: "$" },
    thb: { value: "thb", label: "🇹🇭 THB", symbol: "฿" },
    usd: { value: "usd", label: "🇺🇸 USD", symbol: "$" },
  },
  setCurrencyAll: (currencyAll) => set({ currencyAll }),
  fingerprint: null,
  setFingerprint: (fingerprint) => set({ fingerprint }),
}));

export default useBearStore;
